import { getApolloContext } from "@apollo/client";
import { useAtomValue } from "jotai";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useIdle } from "react-use";
import { v4 } from "uuid";
import { SupportedPageEnumType } from "../@types/generated/types";
import { MIXPANEL_CATEGORIES } from "../constants/mixpanel";
import { InterfaceSessionContext, SessionContext } from "../context";
import { latestSessionAtom } from "../stateManagement/atoms/heartbeat.atom";
import useHeartbeatStore from "../stateManagement/stores/heartbeat.store";
import { Heartbeat } from "../stateManagement/types/heartbeat.types";
import { useProfile } from "./profileProvider/ProfileProvider";

const SESSION_TIME = "session_time";
const TIMER_TTL = "timer_ttl";
const TAB_ID = v4();

export const useSession = () =>
  useContext<InterfaceSessionContext>(SessionContext);

export const SessionProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { query, pathname } = useRouter();
  // Get the context procedurally since it may not exist yet
  const { client } = useContext(getApolloContext());
  const [isIdle, setIsIdle] = useState(false);
  const [sessionTimeoutValue, setSessionTimeoutValue] = useState(60000 * 5);
  const idleTracking = useIdle(sessionTimeoutValue);
  const latestSession = useAtomValue(latestSessionAtom);

  const queryRef = useRef(query);
  const pageRef = useRef<SupportedPageEnumType | "landing">("landing");
  const [heartbeatSuccess, setHeartbeatSuccess] = useState<boolean>(true);
  const [time, setTime] = useState<number>(0);
  const [isActiveTab, setIsActiveTab] = useState<boolean>();

  const { token } = useProfile();

  const { sendHeartbeat, getLastestSession } = useHeartbeatStore();

  // Used to broadcast to other tabs that this tab is currently active
  let tabBroadcast: BroadcastChannel;

  // Tabs can receive their own message so we're sending a tab id
  // and ignoring if receiving our own tab id
  const onBroadcastMessage = (ev: any) => {
    if (ev.data !== TAB_ID) {
      setIsActiveTab(false);
    }
  };

  useEffect(() => {
    try {
      tabBroadcast = new BroadcastChannel("tab");
      tabBroadcast.onmessage = onBroadcastMessage;
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    queryRef.current = query;
  }, [query]);

  const onVisible = () => {
    if (document.visibilityState === "visible") {
      // letting other tabs know that this tab is active
      if (tabBroadcast) tabBroadcast.postMessage(TAB_ID);
      const now = new Date().getTime();

      // fetching the latest time stored in localstorage
      // note that localstorage is shared among all tabs/windows with the
      // same sub-domain

      const currentTimeString = window.localStorage.getItem(SESSION_TIME);
      const timerTtl = window.localStorage.getItem(TIMER_TTL);

      const currentTime = currentTimeString && parseInt(currentTimeString);
      // Is the last time picked up from localStorage older than 30 min?
      const expired = (timerTtl && now > parseInt(timerTtl)) || true;

      if (currentTime && !expired) {
        setTime(currentTime);
      }
      setIsActiveTab(true);
    }
  };

  const isStudioStages = (page: string | string[] | undefined) => {
    if (!page || Array.isArray(page)) {
      return false;
    }
    return [
      SupportedPageEnumType.Transcription,
      SupportedPageEnumType.Translation,
      SupportedPageEnumType.Speech
    ].includes(page as SupportedPageEnumType);
  };

  useEffect(() => {
    onVisible();
    document.addEventListener("visibilitychange", onVisible);
    return () => {
      document.removeEventListener("visibilitychange", onVisible);
      if (tabBroadcast) tabBroadcast.close();
    };
  }, []);

  useEffect(() => {
    if (query.page === "videoDetails") {
      pageRef.current = SupportedPageEnumType.VideoDetails;
    } else if (query.page === "channelDetails") {
      pageRef.current = SupportedPageEnumType.ChannelDetails;
    } else if (pathname === "/studio") {
      if (isStudioStages(query.page)) {
        pageRef.current = query.page as SupportedPageEnumType;
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const tunedMode = Boolean(Number(urlParams.get("tunedMode")));
        pageRef.current = tunedMode
          ? SupportedPageEnumType.StudioTunedMode
          : SupportedPageEnumType.Studio;
      }
    } else if (pathname === "/output-review") {
      pageRef.current = SupportedPageEnumType.OutputReview;
    } else if (pathname === "/") {
      // We do not want to send a heartbeat on the landing page
      pageRef.current = "landing";
    } else {
      pageRef.current = SupportedPageEnumType.Dashboard;
    }
  }, [query, pathname]);

  useEffect(() => {
    let ch: ReturnType<typeof setInterval> | undefined;
    if (!isIdle && client && isActiveTab) {
      const event = {
        category: MIXPANEL_CATEGORIES.TIMER,
        action: "start"
      };
      mixpanel.track("timer start", event);

      const callHeartbeat = async () => {
        if (pageRef.current === "landing") {
          // Do not record time on landing page ("/" route)
          return;
        } else if (
          pageRef.current === "videoDetails" &&
          !query.translatedVideoID
        ) {
          // We require tvId for videoDetails page.
          // When creating a new video, we fetch tvId using svId and lang
          // after mount. So do not send a heartbeat until we have it.
          return;
        }

        if (token.current) {
          try {
            if (!process.env.BINDER_URI) {
              throw new Error("BINDER_URI is not set");
            }

            const body: Heartbeat = {
              activityName: pageRef.current,
              modelEnvironment: process.env!.BINDER_URI
            };
            if (pageRef.current == SupportedPageEnumType.OutputReview) {
              body.modelName = "outputGroup";
              // @ts-ignore
              body.modelId = queryRef.current.outputGroupId;
            } else if (
              queryRef.current.translatedVideoID ||
              queryRef.current.translatedVideoId
            ) {
              body.modelName = "translatedVideo";

              // @ts-ignore
              body.modelId =
                queryRef.current.translatedVideoID ||
                queryRef.current.translatedVideoId;
            } else if (
              queryRef.current.translatedChannelID ||
              queryRef.current.translatedChannelId
            ) {
              body.modelName = "translatedChannel";
              // @ts-ignore
              body.modelId =
                queryRef.current.translatedChannelID ||
                queryRef.current.translatedChannelId;
            }

            sendHeartbeat(body)
              .then(() => setHeartbeatSuccess(true))
              .catch(() => setHeartbeatSuccess(false));
          } catch (error) {
            console.error(error);
          }
        }
      };
      ch = setInterval(callHeartbeat, 10000);
      // Call right away on the first load
      // If user keeps refreshing the page to try to game, binder will always return the last session without updating it
      callHeartbeat();
    }

    return () => {
      if (token.current) {
        getLastestSession();
      }
      if (ch) {
        clearInterval(ch);
        const event = {
          category: MIXPANEL_CATEGORIES.TIMER,
          action: "idle"
        };
        mixpanel.track("timer idle", event);
      }
    };
  }, [isIdle, client, isActiveTab]);

  let i: ReturnType<typeof setInterval> | undefined;
  useEffect(() => {
    if (latestSession) {
      setTime(t => Math.max(latestSession?.active_duration_sec, t));
    }

    if (!isIdle) {
      i = setInterval(() => {
        setTime(t => t + 1);
      }, 1000);
    }

    return () => {
      if (i) {
        clearInterval(i);
      }
    };
  }, [isIdle, latestSession?.sessionId]);

  useEffect(() => {
    if (isActiveTab) {
      window.localStorage.setItem(SESSION_TIME, time.toString());
      // 30 minutes ttl in milliseconds
      const ttl = new Date().getTime() + 30 * 60 * 1000;
      window.localStorage.setItem(TIMER_TTL, ttl.toString());
    }
  }, [time, isActiveTab]);

  return (
    <SessionContext.Provider
      value={{
        heartbeatSuccess,
        isIdle,
        setIsIdle,
        setSessionTimeoutValue,
        idleTracking,
        totalTime: time
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
